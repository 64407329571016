<template>
    <div>
        <div class="show-code">
            <el-tooltip class="item" effect="dark" content="Copy to clipboard" placement="top">
                <i class="el-icon-document-copy copy" v-clipboard:copy="codeInfo" v-clipboard:success="clipboardSuccess"
                    v-clipboard:error="clipboardError"></i>
            </el-tooltip>
            <prism-editor class="my-editor" v-model="codeInfo" aria-disabled :highlight="highlighter"
                :lineNumbers="false" :readonly="true" :autoStyleLineNumbers="true"></prism-editor>
        </div>
    </div>
</template>

<script>
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';

export default {
    name: 'PrismEditorCode',
    components: {
        PrismEditor
    },
    props: ['code', 'demo'],
    data() {
        return {
            codeInfo: '',
        };
    },
    beforeCreate() {
        // console.log("子组件：beforeCreate", this.$props.demo)
        console.log("子组件：beforeCreate") //未加载会报错
    },
    created() {
        console.log("子组件：created", this.$props.demo)
        this.codeInfo = this.$props.code
    },
    beforeMount() {
        console.log("子组件：beforeMount", this.$props.demo)
    },
    mounted() {
        console.log("子组件：mounted", this.$props.demo)
    },

    methods: {
        //复制文本成功回调
        clipboardSuccess(e) {
            this.$message(
                {
                    message: '复制成功',
                    type: 'success',
                }
            );
            console.log(e.text)
        },

        //复制文本失败回调
        clipboardError(e) {
            this.$message(
                {
                    message: '复制失败',
                    type: 'error',
                }
            );
            console.log(e.text)
        },

        highlighter(code) {
            return highlight(code, languages.js) // returns html
        },

    },
}
</script>

<style scoped lang="less">
.show-code {
    width: 100%;
    background-color: rgba(8, 8, 8, 0.8);
    border-radius: 10px;
    padding: 20px;
    position: relative;
    font-family: sans-serif;

    .copy {
        position: absolute;
        top: 25px;
        right: 25px;
        color: #afaeae;
        z-index: 9;
    }

    .copy:hover {
        color: #eee;
        cursor: pointer;
    }

    .my-editor {
        background: rgba(148, 140, 140, 0.06);
        color: #ccc;
        font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
        font-size: 14px;
        line-height: 1.5;
        padding: 5px;
    }

    .my-editor-title {
        color: white;
        margin-top: 10px
    }
}
</style>